<template>
  <div class="about">
    <div class="directory">
      <p :class="['title', {active: curDire==='us'}]" @click="taggleClass('us')">联系我们</p>
      <p :class="['title', {active: curDire==='site'}]" @click="taggleClass('site')">网站声明</p>
    </div>
    <div class="aboutMain">
      <div v-if="curDire==='us'" class="siteState">
        <div class="title">
          <h1><img src="@/assets/images/aboutSix-2.png" />缺钱么隐私政策与版权声明</h1>
        </div>
        <p>
          www.queqianme.com（以下简称“缺钱么”）由广东缺钱么网络科技有限公司运营，我们注重保护用户个人信息及个人隐私。本隐私权保护声明解释了用户个人信息收集和使用的有关情况，本隐私权保护声明适用于缺钱么的所有相关服务。
        </p>
        <h4>关于隐私政策</h4>
        <p>
          缺钱么旗下的缺钱么APP是为用户提供多种类型的互联网金融消费服务平台，包括消费分期等。在您注册、使用我们服务时，经您的同意，我放收集与个人身份有关的信息，例如姓名、住址、手机号码等。关于您的个人资料（包括但不限于您的姓名、地址、电话号码或电子邮件地址），除非您自愿提供给我们，否则我方不会去收集和保留。如果您主动向我们提供了个人资料，包括您的姓名、公司地址、电子邮箱和电话号码等，我们将有权记录信息。我们可能基于以下几个目的保留和使用这些资料：首先，我们可能会储存资料，以便更好地了解您的需求，获知如何改进我们的产品和服务；其次，我们可能保留您以前合作的信息，以便您再次合作时为您定制最优的服务；再次，我们可能使用您的电子邮箱地址，以便向您发送我们的新技术及服务的最新消息。总而言之，我们的目的是为您提供更好、更优的服务。同时会不时更新本隐私权保护声明，我们会在网页上发布对隐私权声明所做的变更内容。
        </p>
        <h4>关于版权声明</h4>
        <p>
          1.缺钱么官网上发布的内容，为本站原创内容，如未经我们及/或相关权利方事先书面同意，任何人不得网站资源，转载、使用网站文章且未注明出处者，否则将追究侵权者的法律责任。您若需此方面的服务，请和本网站相关部门人员接洽。
        </p>
        <p>
          2.官网的标识、源代码、及所有页面的版式设计等，版权归广东缺钱么网络科技有限公司所有。未经授权，不得用于除缺钱么之外的任何站点。
        </p>
        <p class="noIndent">*以上关于隐私政策和版权声明的最终解释权归广东缺钱么网络科技有限公司所有。</p>
      </div>
      <div v-if="curDire==='site'" class="contactUs">
        <img src="@/assets/images/map.jpg" alt="" class="map">
        <address>
          <strong>从这里，你可以找到我们！</strong><br>
          广东缺钱么网络科技有限公司<br>
          公司地址：广州市荔湾区康王中路486号1902房<br>
          邮编：510000<br>
          如果您在使用缺钱么（ www.queqianme.com）的过程中有任何疑问，请您与缺钱么客服人员联系。<br>
          客服电话：400-9305558<br>
          客服邮箱：kefu@queqianme.com<br>
          <span style="color:red;">*</span>
          <span>注：(对于支付宝还款的客户，可以将“转账截图、姓名和注册手机号”等信息发送到客服邮箱，相关人员在确认之后，会第一时间为您更新账户还款状态)</span>
        </address>
        <h4>媒体采访</h4>
        <address>如果有媒体采访需求，请将您的媒体名称、采访纲要、联系方式发送至： media@queqianme.com ，我们会尽 快与您联系。</address>
        <h4>商务合作</h4>
        <address>
          如果贵公司希望与我们建立商务合作关系，形成优势互补，请将合作意向进行简要描述并发送至： media@queqianme.com ，我们会尽快与您联系。<br>
          如果您还希望了解我们更多信息，请关注我们：<br>
          微信公众平台：queqianme<br>
          新浪微博 http://weibo.com/queqianmeapp<br>
        </address>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      curDire: 'us'
    }
  },
  methods: {
    taggleClass (name) {
      this.curDire = name
    }
  }
}
</script>

<style lang="scss">
.about {
  width: 1170px;
  margin: 55px auto;
  .directory {
    float: left;
    border: 1px solid #e8e8e8;
    overflow: hidden;
    .title {
      width: 252px;
      height: 49px;
      line-height: 49px;
      text-align: center;
      border-bottom: 1px solid #e8e8e8;
      cursor: pointer;
    }
    .title:last-child {
      border-bottom: 0 solid #000;
    }
    .active {
      background: #e8721d;
    }
  }
  .aboutMain {
    margin-left: 300px;
    padding: 30px;
    border: 1px solid #e8e8e8;
    .title {
      height: 40px;
      border-bottom: 1px solid #e8e8e8;
      margin-bottom: 30px;
      img {
        margin: 0 10px 0 0;
        line-height: 37px;
      }
      h1 {
        float: left;
        font-size: 20px;
        color: #e8721d;
        line-height: 37px;
        border-bottom: 3px solid #e8721d;
      }
    }
    h4 {
      font-size: 16px;
      color: #f47920;
      font-weight: normal;
      padding-left: 10px;
      border-left: 2px solid #f47920;
      margin-bottom: 30px;
    }
    p {
      text-indent: 2em;
      font-size: 14px;
      color: #666666;
      display: inline-block;
      margin-bottom: 30px;
      line-height: 1.9230769231em;
    }
    .noIndent {
      text-indent: 0;
      color: #f47920;
    }
    .contactUs {
      img {
        width: 100%;
      }
      address {
        font-style: normal;
        line-height: 2em;
        margin-bottom: 30px;
        span {
          font-size: 12px;
        }
      }
      p {
        text-indent: 0;
        .conform {
          color: #f47920;
        }
      }
    }
  }
}
</style>
